.application-selector {
    --page-padding-block: var(--spacing-6);

    max-height: calc(100vh - (2 * var(--page-padding-block)));

    &--outside-of-modal {
        width: min(100%, 700px);
        border-radius: var(--border-radius-2);
        background-color: var(--color-white);
    }

    @media screen and (width < 832px) {
        max-height: 100vh;
    }
}
