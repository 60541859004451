.property-detail-section {
    padding-bottom: var(--spacing-5);
    border-bottom: var(--border-width-1) solid var(--color-gray-400);

    .grid {
        grid-template-columns: repeat(auto-fill, minmax(var(--size-13), 1fr));
        align-items: center;

        &--auto-fit {
            grid-template-columns: repeat(
                auto-fit,
                minmax(var(--size-13), 1fr)
            );
        }
    }
}
