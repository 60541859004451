.google-maps-view-tab-list {
    border-bottom-color: var(--color-gray-300);

    .tab {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: var(--spacing-4);
        text-decoration: none;
        font-size: var(--font-size-0);
        font-weight: var(--font-weight-6);
        color: var(--color-gray-500);

        &:hover:not(&--active) {
            border-color: var(--color-brand);
            color: var(--color-brand);
        }

        &:focus {
            box-shadow: none;
        }

        &--active {
            border-color: var(--color-primary-b-500);
            color: var(--color-primary-b-500);
        }
    }
}
