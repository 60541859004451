.grid {
    grid-template-columns: repeat(2, 1fr);
    padding: var(--spacing-4);
}

.viewMore {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: --var(--space-2);
}
