.section {
    padding-bottom: var(--spacing-4);
    border-bottom: var(--border-width-1) solid var(--color-gray-400);

    .grid {
        grid-template-columns: repeat(auto-fill, minmax(var(--size-13), 1fr));

        &--auto-fit {
            grid-template-columns: repeat(
                auto-fit,
                minmax(var(--size-13), 1fr)
            );
        }
    }

    [data-testid='banner'] {
        font-size: 12px;
    }

    &:last-of-type,
    &--modal {
        padding-bottom: var(--spacing-0);
        border-bottom: none;
    }

    &--tds svg {
        margin-right: var(--spacing-0) !important;
    }

    // Description field
    &--details :global #description {
        height: auto;
    }
}
