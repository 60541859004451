.header {
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: var(--spacing-4);
    border-bottom: 1px solid var(--color-gray-400);
}

.badgeContainer {
    gap: var(--space-1);
    padding-right: var(--space-2);
}

.card {
    min-width: var(--size-14);
    flex: 1 1 calc(33% - (var(--spacing-2) / 2));
    height: 481px;
    padding: 0;
    overflow: hidden;
}

.card.cardUnderwriter {
    flex: 1 1 35%;
}

.checked {
    color: var(--color-green-600);
}

.section {
    background-color: #fafafa;
    padding: 10px;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 400;
    border-radius: 10px;
    align-items: center;
}

.section:hover {
    background-color: var(--color-blue-200);
    color: var(--color-blue-100);
    outline: 1px solid var(--color-blue-200);
    border-radius: 6px;
}

.complete {
    background-color: var(--color-green-200);
}

.complete:hover {
    background-color: var(--color-green-400);
}

.badge {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1px 8px;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    background: #ced9eb;
    border-radius: 100px;
}

.scrollArea {
    height: 400px;
    &:hover {
        overflow-y: scroll;
    }
}
