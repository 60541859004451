.property_card {
    padding: var(--spacing-4) 0;
    .property_header {
        min-height: var(--size-8);
        padding: 0 var(--spacing-4);
    }

    .property_info {
        padding: 0 var(--spacing-4);
    }

    // Property information grid layout
    .property_info_grid {
        padding: 0 var(--spacing-4);
        grid-template-columns: repeat(2, auto);
        grid-template-areas:
            'purpose acceptanceDate'
            'purchasePrice estimatedPropertyValue'
            'address address';
        column-gap: var(--spacing-4);
    }
}
