.connect-accounts-modal-wrapper {
    :global .modal-body {
        /* Only way to update the main padding without affecting
           all the modals (Needed to use scrollable modal body) */
        padding: var(--spacing-0) !important;
    }

    .connect-accounts-modal {
        --page-padding-block: var(--spacing-6);

        max-height: calc(100vh - (2 * var(--page-padding-block)));
        min-height: 348px;

        .header {
            padding: var(--spacing-5) var(--spacing-6);
            border-bottom: var(--border-width-1) solid
                var(--color-foreground-subtle);
            color: var(--color-black);

            .title {
                margin-block: var(--spacing-0);
            }
        }
    }
}
