.applicant-employments {
    max-height: var(--size-13);

    .list {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-4);
        margin: var(--spacing-0);
        padding: var(--spacing-0);
        list-style: none;

        .applicant-employment {
            border-radius: var(--border-radius-2);
            background-color: var(--color-background-muted);
            color: var(--color-black);

            .content {
                padding: var(--spacing-3);
                border-radius: inherit;
                border: var(--border-width-1) solid
                    var(--color-background-muted);
                cursor: pointer;

                .employment-type {
                    font-size: 12px;
                }

                .arrow-icon {
                    display: none;
                    color: inherit;
                }

                &:hover:not(&--active) {
                    border: var(--border-width-1) solid
                        var(--color-primary-b-500);

                    .arrow-icon {
                        display: block;
                        color: var(--color-primary-b-500);
                    }
                }

                &--active {
                    border: var(--border-width-1) solid
                        var(--color-primary-b-500);
                    background-color: var(--color-primary-b-500);
                    color: var(--color-white);

                    .arrow-icon {
                        display: block;
                    }
                }
            }
        }
    }
}
