.rate_card {
    padding: var(--spacing-4) 0;

    .rate_header {
        min-height: var(--size-8);
        padding: 0 var(--spacing-4);
        > div {
            display: flex;
            align-items: center;
            flex-grow: 1;
        }
    }

    // Rate card - divider
    > hr {
        margin-bottom: var(--spacing-0);
    }

    .rate_info {
        padding: 0 var(--spacing-4);
        :global .cell-value {
            font-size: var(--font-size-1);
        }

        :global .cell-value-rate {
            font-size: var(--font-size-4);
        }
    }
}
