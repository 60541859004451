.property-location-modal-panel {
    margin: var(--spacing-6);

    // Only way to update the main padding without affecting all the modals
    :global .modal-body {
        padding: var(--spacing-0) !important;
    }

    .property-location-modal {
        max-height: calc(100vh - (2 * var(--spacing-6)));

        .header {
            padding: var(--spacing-5) var(--spacing-7) var(--spacing-5)
                var(--spacing-5);
            border-bottom: var(--border-width-1) solid var(--color-gray-300);
        }

        .body {
            padding: var(--spacing-4) var(--spacing-5);

            .google-maps,
            .applicant-info {
                flex-basis: 50%;
            }

            .applicant-info > .form {
                padding-bottom: var(--spacing-4);
                border-bottom: var(--border-width-1) solid var(--color-gray-300);

                // Update subject property cell
                [data-testid='cell-subjectProperty'] {
                    padding: var(--spacing-0);
                    gap: var(--spacing-1);

                    [data-testid='cell-label-subjectProperty'] {
                        color: var(--color-black);
                    }

                    [data-testid='cell-value-subjectProperty'] {
                        color: var(--color-primary-b-500);
                    }
                }
            }

            .google-maps > iframe {
                min-height: var(--size-14);
            }

            @media screen and (width < 900px) {
                flex-direction: column;

                .google-maps,
                .applicant-info {
                    flex-basis: 100%;
                }
            }
        }
    }
}
