.pdf-preview {
    --pdf-preview-content-height: 694px;
    --pdf-preview-error-content-height: 566px;
    --pdf-preview-header-color: #323639;

    .header {
        min-height: 56px;
        padding: var(--spacing-3) var(--spacing-5);
        background-color: var(--pdf-preview-header-color);
        color: var(--color-gray-300);
    }

    .content {
        height: var(--pdf-preview-content-height);
    }

    &--error {
        .warning-banner {
            .fix-issues-link,
            .nesto-support-link {
                padding: var(--spacing-0);

                &:focus {
                    box-shadow: none;
                }
            }

            .fix-issues-link {
                flex-shrink: 0;
            }

            .nesto-support-link {
                font-weight: var(--font-weight-6);
                color: var(--color-black);
            }
        }

        .preview .content {
            height: var(--pdf-preview-error-content-height);
            background-color: var(--color-gray-300);
        }
    }

    &--skeleton .content {
        padding: var(--spacing-3) var(--spacing-6) var(--spacing-0);
        overflow-y: hidden;

        .logo {
            margin-bottom: var(--spacing-3);
        }

        .top-container {
            margin-bottom: var(--spacing-6);
        }
    }
}
