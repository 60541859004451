.canada-life-card {
    --card-padding: var(--spacing-0);

    flex: 1 1 calc(33% - (var(--spacing-2) / 2));
    min-width: var(--size-14);
    min-height: var(--size-14);

    &__header {
        --card-header-margin: var(--spacing-0);

        display: flex;
        align-items: center;
        gap: var(--spacing-3);
        padding: var(--spacing-4);
        border-bottom: var(--border-width-1) solid var(--color-gray-400);
    }

    .scroll-area {
        position: relative;
        padding-inline: var(--spacing-4);

        .canada-life-card__body {
            padding-block: var(--spacing-4);

            // CellData Component
            :global .cell {
                padding: var(--spacing-0);
                gap: var(--spacing-1);

                .cell-value {
                    font-size: var(--font-size-1);
                }
            }
        }
    }
}
