.container {
    padding: var(--spacing-1) var(--spacing-2);
    border-radius: var(--spacing-3);
    margin: var(--spacing-2) 0;
    background-color: var(--color-gray-100);
    &:hover {
        background-color: var(--color-gray-200);
        cursor: pointer;
        transition: background-color 0.25s ease;
    }
}

.title {
    margin-left: var(--spacing-2);
    font-size: var(--font-size-0);
}

.totalDocuments {
    margin-left: var(--spacing-2);
}
