.accounts {
    .radio {
        position: relative;
        top: 5px;
        width: var(--size-4);
        height: var(--size-4);
        margin: var(--spacing-0);
        accent-color: var(--color-primary-b-500);
    }

    .accordions {
        .accordion {
            --accordion-padding: var(--spacing-0);

            border: none;

            [data-testid='accordion-summary'] {
                padding-block: var(--spacing-3);

                // Target the expand/collapse button
                > button {
                    --button-icon-size: auto;

                    color: var(--color-black);

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        &:last-child .account-details {
            padding-bottom: var(--spacing-4);
        }
    }
}
