.account-details {
    grid-template-columns: repeat(auto-fit, minmax(var(--size-13), 1fr));

    :global {
        .cell {
            gap: var(--spacing-1);
            padding: var(--spacing-0);
        }

        .cell-value {
            font-weight: var(--font-weight-5);
        }
    }
}
