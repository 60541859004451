.checkbox {
    flex-shrink: 0;
    min-width: min-content;

    > input[type='checkbox']:focus ~ svg {
        background-color: rgba(66, 153, 225, 0.5);
    }

    .checkbox_icon {
        display: block;
        margin-right: var(--spacing-2);
        color: var(--color-blue-500);
    }
}

// Checkbox disabled
.checkbox.checkbox--disabled {
    .checkbox_icon {
        color: #c9cacf;
    }
}

// Conditions checkbox
.checkbox.checkbox--square {
    > input[type='checkbox']:focus ~ svg {
        background-color: transparent;
    }
}

// Conditions checkbox disabled
.checkbox.checkbox--square.checkbox--disabled {
    .checkbox_icon > rect:first-child {
        stroke: var(--color-light-1);
    }

    .checkbox_icon > rect:nth-child(2) {
        fill: var(--color-light-1);
    }
}

// Checkbox hidden
.checkbox.checkbox--hidden {
    visibility: hidden;
}
