.sidebar {
    --sidebar-width: 240px;
    --sidebar-height: 100%;
    --sidebar-min-height: 100%;
    --sidebar-background-color: var(--color-white);
    --header-background-color: var(--color-white);
    --footer-padding: var(--spacing-6) var(--spacing-4) var(--spacing-5);
    --footer-background-color: var(--color-white);
    --navigation-item-background-color: var(--color-white);
    --navigation-item-background-color-hover: var(--color-blue-200);
    --navigation-item-color: var(--color-black);
    --navigation-item-color-hover: var(--color-blueberry-500);
    --transition-duration: 0;

    z-index: var(--z-sidebar);
    padding-inline: var(--spacing-0);
    border-right: var(--border-width-1) solid var(--color-gray-400);
    box-shadow: none;

    // Content
    > div:nth-child(2) {
        position: relative;
        overflow-y: hidden;
    }

    .list {
        list-style-type: none;
        margin: var(--spacing-0);
        padding: var(--spacing-0);

        > li {
            margin-bottom: var(--spacing-3);

            &:last-child {
                margin-bottom: var(--spacing-0);
            }
        }
    }

    .footer {
        .link_wrapper {
            margin-bottom: var(--spacing-3);

            .link {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--spacing-2);
                padding: var(--spacing-2);
                text-decoration: none;
                border-radius: var(--border-radius-2);
                background-color: var(--color-light-6);
                color: var(--color-brand);

                > div {
                    flex-shrink: 0;
                    line-height: var(--line-height-1);
                }
            }
        }

        .currentlyWorkingOn {
            max-width: 90%;
            margin-bottom: var(--spacing-1);
        }

        .actions {
            > button {
                min-width: var(--size-7);
                min-height: var(--size-7);
                padding: var(--spacing-1);
                border-radius: var(--border-radius-2);
                color: var(--color-black);

                &:hover {
                    box-shadow: none;

                    &:not(:last-child) {
                        background-color: var(--color-blue-200);
                        color: var(--color-blueberry-500);

                        svg path {
                            fill: var(--color-blueberry-500);
                        }
                    }
                }

                &:last-child {
                    margin: var(--spacing-0) var(--spacing-0) var(--spacing-0)
                        auto;
                    background-color: var(--color-light-5);
                }
            }
        }
    }
}

.sidebar__narrow {
    --header-padding: var(--spacing-5) var(--spacing-2) var(--spacing-6);
    --footer-padding: var(--spacing-6) var(--spacing-2) var(--spacing-5);

    // Sidebar - header
    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer {
        .link_wrapper .link {
            align-items: center;
            justify-content: center;
            padding: var(--spacing-0);
            background-color: transparent;

            // Avatar
            > div {
                height: var(--size-7);
            }
        }
    }
}
