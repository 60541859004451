.reason {
    margin: var(--spacing-1);
    width: 70%;
}

.action {
    border-radius: 10px;
    margin-left: var(--spacing-2);
}

.divider {
    margin: var(--spacing-6) 0;
}
