.amount-percentage-field {
    .field-container {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-2);
        width: 100%;

        @media only screen and (max-width: 1440px) {
            flex-direction: column;
        }
    }
    .field {
        flex: 1;
    }
}

.checkbox-field {
    input[type="checkbox"] {
        accent-color: var(--color-blue-500);
    }
}
