.applicant_card {
    padding: var(--spacing-4) 0;

    .applicant_header {
        min-height: var(--size-8);
        padding: 0 var(--spacing-4);
    }

    .applicant_element {
        padding: 0 var(--spacing-4);
    }

    // Applicant information grid layout
    .applicant_info_grid {
        grid-template-columns: repeat(2, 1fr);
        column-gap: var(--spacing-5);
    }
}
