.connect-accounts-footer {
    padding: var(--spacing-5) var(--spacing-6);
    border-top: var(--border-width-1) solid var(--color-foreground-subtle);

    .banner {
        margin-bottom: var(--spacing-4);
    }

    .checkbox-wrapper {
        margin-bottom: var(--spacing-7);

        .checkbox {
            width: var(--size-3);
            height: var(--size-4);
            margin: var(--spacing-0);
            accent-color: var(--color-primary-b-500);
        }
    }
}
