.grid {
    grid-template-columns: repeat(auto-fill, minmax(var(--size-13), 1fr));
    gap: var(--spacing-4);

    .applicant-field {
        grid-area: applicant;
    }

    .type-field {
        grid-area: type;
    }

    .value-field {
        grid-area: value;
    }

    .down-payment-field {
        grid-area: downPayment;
    }

    .property-address-field {
        align-self: end;
    }

    &--modal {
        grid-template-areas:
            'applicant applicant'
            'type value'
            'downPayment .';
        grid-template-columns: repeat(auto-fit, minmax(var(--size-13), 1fr));
    }
}
