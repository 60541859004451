.banner-notifications {
    position: relative;

    .view-button {
        padding: var(--spacing-0);

        &:focus {
            box-shadow: none;
        }

        &--active {
            text-decoration: underline;
        }
    }
}
