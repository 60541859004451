.popup {
    position: absolute;
    top: calc(48px + var(--spacing-2));
    z-index: var(--z-dropdown);
    width: min(var(--size-15), 100%);
    max-height: var(--size-13);
    overflow: hidden;
    border-radius: var(--border-radius-2);
    background-color: var(--color-white);
    box-shadow: var(--shadow-medium);

    &__header {
        padding: var(--spacing-3);
    }

    // Scroll-area
    > div {
        position: relative;

        [data-testid='banner'] {
            border-radius: var(--border-radius-0);
        }
    }
}
