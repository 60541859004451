.co-applicant-search-modal-wrapper {
    :global .modal-body {
        /* Only way to update the main padding without affecting
           all the modals (Needed to use scrollable modal body) */
        padding: var(--spacing-0) !important;
    }

    .co-applicant-search-modal {
        --page-padding-block: var(--spacing-6);

        max-height: calc(100vh - (2 * var(--page-padding-block)));
        min-height: var(--size-13);

        .header {
            padding: var(--spacing-5) var(--spacing-6);
            border-bottom: var(--border-width-1) solid
                var(--color-foreground-subtle);
            color: var(--color-black);

            .title {
                margin-block: var(--spacing-0);
            }
        }

        .scroll-area {
            position: relative;
            padding-inline: var(--spacing-6);

            .grid {
                grid-template-columns: repeat(
                    auto-fit,
                    minmax(var(--size-13), 1fr)
                );
                padding-block: var(--spacing-4);
            }

            .error-message {
                min-height: var(--size-3);
            }
        }

        .footer {
            padding: var(--spacing-5) var(--spacing-6);
            border-top: var(--border-width-1) solid
                var(--color-foreground-subtle);
        }
    }
}
