.scenarios {
    :global .react-tooltip {
        --tooltip-background-color: var(--color-white);
        --tooltip-text-color: var(--color-black);
        z-index: var(--layer-important);
    }

    margin-top: -10px !important;
    padding: 0 !important;
    position: relative;
    overflow: hidden;

    [data-testid='input-addon'],
    [data-testid='input-addon-left'] {
        font-size: var(--font-size-2);
        max-width: var(--size-3);
    }

    .scenarios-form {
        padding: var(--spacing-5);

        .scenarios-form-section {
            width: 100%;
        }

        .scenarios-form-section-grid {
            --grid-template-columns: 3fr 4fr;
            --grid-gap: var(--spacing-3);
            grid-auto-rows: minmax(var(--size-7), auto);

            margin-top: var(--spacing-4);
            align-items: center;
        }
    }

    .missing-heloc-product-banner {
        .select-product {
            white-space: nowrap;
        }
    }

    .error-banner {
        grid-column: 1 / -1;
    }

    .debt-assessment-container {
        grid-template-columns: minmax(100%, 1fr);
        background-color: var(--color-gray-300);
        width: 50%;
        padding: var(--spacing-5);
        align-items: start;
    }
}

.scenario-modal {
    .scenario-container {
        margin: 0 auto;
    }

    .scenario-heading {
        margin-bottom: var(--spacing-3);
    }
}
