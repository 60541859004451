.main-applicant__card {
    max-height: var(--size-15);
    overflow-y: hidden;

    [data-testid='scroll-area'] {
        position: relative;
        padding: var(--spacing-0) var(--spacing-4) var(--spacing-4);

        .grid {
            grid-template-columns: repeat(2, 1fr);

            &:first-of-type {
                padding-top: var(--spacing-4);
            }
        }

        hr {
            margin-block: var(--spacing-4);
        }
    }
}
