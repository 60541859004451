.investor-accent {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top-left-radius: var(--border-radius-2);
    border-top-right-radius: var(--border-radius-2);
}

.investor-accent__badge {
    position: absolute;
    top: calc(var(--size-2) * -1); // Needs to be negative to move above
    right: 2px;
}
