.container {
    display: grid;
    gap: var(--spacing-5);
    padding-right: calc(var(--size-9) - 4px);
    margin-top: var(--space-2);
}

.header {
    min-height: var(--size-8);
    padding: var(--spacing-4);
    border-bottom: 1px solid var(--color-gray-400);
}

.main-divider {
    margin-block: var(--spacing-0);
}

.card {
    min-width: var(--size-14);
    min-height: var(--size-14);
    flex: 1 1 calc(50% - (var(--spacing-2) / 2));
    display: flex;
    flex-direction: column;
    padding: var(--spacing-0);
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: var(--space-1);
    h2 {
        font-size: var(--size-5);
        font-weight: var(--font-weight-4);
        line-height: 36px;
    }
    h3 {
        font-size: 10px;
        font-weight: var(--font-weight-5);
        line-height: 12px;
    }
}

.applicantHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-3);
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.referral-grid {
    display: flex;
    flex-flow: row wrap;

    .main-applicant,
    .other-mortgages {
        flex: 1 100%;
    }
}
