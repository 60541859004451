.consultant-contact__card {
    .body {
        padding: var(--spacing-4);

        .grid {
            grid-template-columns: repeat(2, 1fr);
        }

        > hr {
            margin-block: var(--spacing-4);
        }
    }
}
