.flags_sidebar {
    --card-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.25);
    --card-shadow-hover: var(--card-shadow);

    position: fixed;
    inset: 0 0 0 auto;
    z-index: var(--z-sidebar);
    width: 400px;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--spacing-0);
    border-radius: 0;
    background-color: var(--color-white);

    // Close button
    > button {
        align-self: flex-start;
        margin-bottom: var(--spacing-5);
    }

    .flags_top_container {
        margin-left: var(--spacing-4);

        // Chip component
        > div {
            font-size: 12px;
        }

        // Grommet flag stroke was not showing
        > svg path {
            stroke: inherit;
        }
    }

    .flags_divider {
        width: auto;
        margin: var(--spacing-4) var(--spacing-0) var(--spacing-0)
            var(--spacing-4);
    }

    .flags_content {
        list-style-type: none;
        flex-grow: 1;
        margin: var(--spacing-0) var(--spacing-0) var(--spacing-0)
            var(--spacing-4);
        padding: var(--spacing-4) var(--spacing-0) var(--spacing-4);

        > li {
            margin-bottom: var(--spacing-6);

            &:last-child {
                margin-bottom: var(--spacing-0);
            }
        }

        // Flag - text
        > li > label > span {
            text-decoration: none;
            color: var(--color-brand);
        }

        > li svg {
            margin-right: var(--spacing-0);
        }

        > li svg > path {
            fill: var(--color-black);
        }

        // Flag - divider
        > li > div {
            display: none;
        }
    }
}
