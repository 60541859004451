.layout {
    position: relative;
    min-height: 100vh;

    .sidebars {
        height: 100vh;
        position: sticky;
        top: 0;
        z-index: var(--layer-3);
    }

    .header {
        position: sticky;
        top: 0;
        z-index: var(--layer-3);
    }

    .main {
        flex: 1 1 0%;
    }

    .section {
        padding: var(--spacing-6);
    }

    .content {
        width: clamp(550px, 90%, 1600px);
        margin-inline: auto;
    }
}
