.container {
    border-radius: 6px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px;
    flex-direction: column;
    min-height: 200px;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}
