.credit-score {
    text-align: left;

    &__divider {
        width: 100%;
    }
}

.credit-score-row {
    width: 100%;
    column-gap: var(--spacing-6);
    row-gap: var(--spacing-1);
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    align-items: center;

    &__pull-credit-cta {
        justify-content: flex-end;

        @media only screen and (max-width: 1800px) {
            justify-content: flex-start;
        }
    }
}
