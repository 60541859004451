.header {
    min-width: 700px;
    margin-bottom: var(--spacing-6);

    .right_container {
        flex-direction: row-reverse;
    }

    .right_container > div {
        margin-top: var(--spacing-0);
        padding: var(--spacing-0);
    }

    .flag_btn {
        // Grommet flag stroke was not showing
        > svg path {
            stroke: inherit;
        }
    }

    @media screen and (min-width: 1200px) {
        min-width: 1000px;

        .right_container {
            flex-direction: row;
        }
    }
}

.main_section {
    min-width: 700px;

    @media screen and (min-width: 1200px) {
        min-width: 1000px;
    }

    // Cards layout
    .cards_layout {
        > div {
            flex: 0 0 calc(50% - var(--spacing-3));

            @media screen and (min-width: 1200px) {
                &:first-child,
                &:nth-child(2) {
                    flex: 0 0 calc(36% - var(--spacing-3));
                }

                // Next step card
                &:first-child {
                    order: 1;
                }

                // Document card
                &:nth-child(2) {
                    order: 3;
                }

                // Applicants card
                &:nth-child(3) {
                    order: 4;
                }

                // Property card
                &:nth-child(4) {
                    order: 5;
                }

                // Rate card
                &:last-child {
                    flex: 0 0 calc(28% - var(--spacing-5));
                    order: 2;
                }
            }
        }
    }
}
