.grid {
    grid-template-columns: 1fr 1fr 1fr 175px 20px;
    gap: var(--spacing-4);

    &--modal {
        grid-template-columns: repeat(auto-fit, minmax(var(--size-13), 1fr));
        grid-template-areas:
            'applicant applicant'
            'description type'
            'amount frequency';

        .applicant-field {
            grid-area: applicant;
        }

        .description-field {
            grid-area: description;
        }

        .type-field {
            grid-area: type;
        }

        .amount-field {
            grid-area: amount;
        }

        .frequency-field {
            grid-area: frequency;
        }
    }
}
