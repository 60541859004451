.application-item {
    --color: var(--color-black);
    --background-color: var(--color-background-muted);
    --active-color: var(--color-white);
    --active-background-color: var(--color-primary-b-500);

    &__button {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-3);
        padding: var(--spacing-4);
        border-radius: var(--border-radius-2);
        border: var(--border-width-1) solid var(--background-color);
        background-color: var(--background-color);
        color: var(--color);

        &:hover {
            border: var(--border-width-1) solid var(--active-background-color);
        }

        &:is(&:hover, &:focus) {
            box-shadow: none !important;
        }
    }

    &--active .application-item__button {
        border: var(--border-width-1) solid var(--active-background-color);
        background-color: var(--active-background-color);
        color: var(--active-color);
    }
}
