.section {
    padding-bottom: var(--spacing-4);
    border-bottom: var(--border-width-1) solid var(--color-gray-400);

    .grid {
        grid-template-columns: repeat(auto-fill, minmax(var(--size-13), 1fr));

        &--auto-fit {
            grid-template-columns: repeat(
                auto-fit,
                minmax(var(--size-13), 1fr)
            );
        }

        &--auto-fit-larger {
            grid-template-columns: repeat(
                auto-fit,
                minmax(var(--size-14), 1fr)
            );
        }
    }

    .incomes {
        grid-template-columns: repeat(3, 0.5fr);
    }

    .editing-incomes {
        grid-template-columns: repeat(2, 0.5fr);
    }

    &--modal {
        padding-bottom: var(--spacing-0);
        border-bottom: none;
    }
}
