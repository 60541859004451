.plaid-monitoring-hits-modal-panel {
    :global .modal-body {
        // Only way to update the main padding without affecting all the modals
        padding: var(--spacing-0) !important;
    }

    @media screen and (width < 832px) {
        max-height: 100vh;
    }

    .plaid-monitoring-hits-modal {
        max-height: calc(100vh - (2 * var(--spacing-6)));

        &__header {
            padding: var(--spacing-5) var(--spacing-7) var(--spacing-4)
                var(--spacing-4);
            border-bottom: var(--border-width-1) solid var(--color-gray-300);
        }

        &__scroll-area {
            position: relative;
            padding-inline: var(--spacing-5);

            .table-hits {
                margin-block: var(--spacing-5);

                .column-nowrap {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
