.next_step_card {
    max-height: 481px;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-4) 0 var(--spacing-0);

    .next_step_header {
        min-height: var(--size-8);
        justify-content: center;
        padding: 0 var(--spacing-4);
    }

    // Card step - divider
    > hr {
        margin-bottom: var(--spacing-0);
        margin-left: var(--spacing-0);
    }

    // Card information
    .next_step_info {
        position: relative;
        flex-grow: 1;
        overflow: hidden;
        padding: 0 var(--spacing-4);

        // Card step - default
        .step {
            grid-template-columns: 36px 1fr;
            align-items: center;
            padding: var(--spacing-4) var(--spacing-0);
            background-color: var(--white);

            // Card step - indicator
            .round_indicator {
                width: var(--size-5);
                height: var(--size-5);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                margin: 6px;
                font-size: 12px;
                font-weight: var(--font-weight-7);
                border-radius: 50%;
                border: 2px solid var(--color-yellow-600);
            }

            > div:last-child {
                column-gap: var(--spacing-3);
                row-gap: var(--spacing-2);
            }

            // Card step - state
            &.step_completed {
                padding-inline: 6px;
                background-color: var(--color-green-200);
            }

            &.next_step,
            &.step_todo:hover {
                align-items: flex-start;
            }

            // Card step - text container
            .text_container {
                flex: 0 1 220px;
            }

            &.step_completed .text_container {
                flex: 0 1 180px;
            }

            // Card step - explanation
            &.step_todo:hover .explanation {
                display: block;
            }

            // Card step - action button
            .action {
                align-self: flex-start;
            }

            &.step_completed .action {
                align-self: center;
                padding: var(--spacing-0);
            }

            &.step_todo:hover .action,
            &.step_completed:hover .action {
                display: inline-flex;
            }

            &.step_todo .action,
            &.step_todo .explanation,
            &.step_completed .action,
            &.step_completed .explanation {
                display: none;
            }
        }
    }
}
